<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item"><a href="#/support-2">我想支持</a></li>
              <li class="breadcrumb-item active" aria-current="page"><a href="#">青少年父母的茁壯路</a></li>
            </ol>
          </nav>
        </section>
        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="text-box">
              <p class="step-text" data-aos="fade-up">ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up" >選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up">為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="select-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜青少年父母的茁壯路｜</h2>
                </div>
                <div class="step-header">
                  <img data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                    <p data-aos="fade-up">
                    </p>
                  <hr data-aos="fade-up">
                </div>
                <div class="select-menu">
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <h3 class="fs-5 text-danger">【女人想想】有限資源與無限韌性 青少年父母的茁壯路<br>
                        陳美伶 發佈於 8 月 8, 2019</h3>
                        <p class="mb-20">
                          文章來源：
                          <a class="fs-5 text-danger" href="https://www.thinkingtaiwan.com/content/7790" target="_blank">https://www.thinkingtaiwan.com/content/7790</a><br><br>
                        </p>
                          <ul class="list mb-60">
                            <li>
                           近兩年，出現好幾起未成年未婚懷孕生子或兒童被虐致死的社會案件新聞報導，大眾聽聞這樣的事件，輿論容易很快地以結果推論事實：因為新聞媒體的描述語句通常是「17歲大學生未婚產子扔垃圾桶」、「16歲少女廁所產子險溺斃」、「孩子疑似受到青少女母親的同居人虐待導致身上多處傷痕」，或是下個聳動標題；而大眾則容易以日常所聽到的、新聞所描述的片段訊息來拼湊對青少女母親的生活故事與全貌，卻忽視了到底是什麼樣的社會情境脈絡，導致青少女懷孕卻不敢主動求助？
                            </li>
                            <li>
                              有人真正關心這位當事人為什麼會懷孕嗎？為什麼「選擇」生下孩子？為了將孩子留在身邊做了什麼樣的努力？</li>
                            <li>
                              我們明瞭這些看似公義的評論，其實對這群青少女母親造成更多的汙名和標籤。她們為孩子所做的努力將再次被掩蓋，反而需要花更多力氣向他人證明自己的努力，才能由負轉正，才能獲得他人認同是個合格的好母親。事實上，他們如何在有限的條件下保持韌性地生活，社會大眾幾乎是無從得知的。
                            </li>
                            <li>
                              <h3>從懷孕到生產至養育 選擇難題與挑戰環環相扣</h3>
                            </li>
                            <li>
                              青少女懷孕服務方案歷經近十年的在地耕耘，看見一個青少女從確認自己懷孕到生產、留養的過程中所經歷的掙扎與矛盾，各個決定之間環環相扣。是否生產並非簡單的要或不要的個人意願選擇題，而是關聯著一段情感與關係的維持；或是受限法律規定而無法取得合法的墮胎管道；亦或考量與等待男方的表態，在等待過程中，胎兒持續留在母體中成長，過了適合終止懷孕的手術時間，繼續妊娠成為唯一的選項。
                             </li>
                             <li>
                               即使過了生產這一關，接下來要面臨留下來自己養或不得不選擇出養的議題，涉及必須思考原生家庭的態度與支持程度、男方的態度與回應、對個人教育與生涯規劃發展的影響、非婚生子女的處遇、婚姻與新生兒照顧計畫等議題。在這當中，當事人的聲音可能被視為重要聲音，但也可能被淹沒在周遭重要他人的意見中。當事人的個人意願不見得被視為重要的參考，只能被動配合他人所討論出來的決定。因此當決定留養，也代表著女性的身分角色即將轉換，隨之而來的則是這個角色被賦予的「責任」──不論是否已準備好站上母親這個位置，甚至是在幾乎沒有親屬資源的情形下，仍堅持將孩子帶在身邊養育。
                             </li>
                             <li>
                                然而，社會與原生家庭對於父母的親職角色有所期待，更遑論是青少年父母。當青少年父母因照顧知識缺乏、育兒壓力高漲、親密關係衝突、支持系統不足或嬰幼兒身心發展較為遲緩，以致子女出現受照顧狀況不佳時，社會及原生家庭對青少年父母的譴責更加嚴厲。例如：「養不起還要生，小孩有這樣的父母好可憐喔」、「這個小孩沒爸爸喔？！」、「你才幾歲，小孩一定顧不好」、「當爸媽了還想要出去玩」、「小孩顧小孩，怎麼顧得好」等等。
                             </li>
                             <li>
                              然而我們看見這些青少年父母，同時是「父母」，也是「青少年」的雙重身分，青少年父母的需求的確有其特殊性，也看見青少年在成為新手父母時所面對的挑戰是一連串且多元的，其中包含新生兒照顧、是否繼續就學、就業、伴侶關係、婆媳關係、自我情緒調適等等。這些青少年父母在有限的支持與資源下學習和適應親職角色，在投入心力和時間去照料孩子食衣住行的同時，卻也為了照顧孩子而壓縮自我需求，包括日常作息、生活型態與人際圈改變等部分，更因為全心投入家庭而受限。甚至，他們在無替代人力的情形下足不出戶，使得這一群正值青少年發展階段的青少年父母，與社區的連結減少，降低與外界接觸的機會，缺乏社會參與。
                             </li>
                             <li>
                              但這些年來陪伴這些青少年父母親學習成為父母的過程，透過跟青少年父母互動時所看見與感受到的經驗，我們強烈相信若支持系統穩定和資源充足，對青少年父母的育兒過程將有相當大的助益，可協助他們適應親職角色，舒緩照顧壓力。透過他們的述說，有助大眾開啟另一個角度認識青少年母親，看見她們的韌性與成熟度遠超過一般想像，在背負道德責難及旁人不信任的同時，如何充滿能量地回應現實生活的壓力與需求。
                             </li>
                             <li>
                              每一個個案故事裡面的主角，可能是我們的鄰居、同事、朋友，甚至是家人。我們相信，每一個人都有此能力，多一些對他者生命的理解，就有機會承接住這些年輕的生命。目前當務之急是重視青少年主體性的發展和平衡，建置友善青少年父母的環境，如此才能回應青少年父母的需求，提供一個整合性的服務，讓懷孕青少女與青少年父母能從其中取得資源。此外，協助他們獲得健康的生活，包括營養、育兒資源、兒童發展等資訊，以維護兒童的健康與發展，且為決定承擔起親職角色之青少年父母提供社會支持。
                             </li>
                         </ul>
                      </li>
                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Article></Article>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'
import Article from '../../components/Article.vue'

export default {
  data () {
    return {

    }
  },
  mounted () {

  },
  components: {
    Consult,
    Article
  }
}
</script>
